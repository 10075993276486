import { initializeApp, getApps, getApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
// use timestamp from firebase
import { Timestamp } from 'firebase/firestore';

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyBWD1r5qOBpGoURFRca22-G1Ff6xLKy3t8",
  authDomain: "asbestos-logistics.firebaseapp.com",
  projectId: "asbestos-logistics",
  storageBucket: "asbestos-logistics.appspot.com",
  messagingSenderId: "812156173563",
  appId: "1:812156173563:web:5974e29761af8680b0045e"
};

const app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// init services
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, storage, auth };
